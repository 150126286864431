// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-bikes-tsx": () => import("./../src/pages/bikes.tsx" /* webpackChunkName: "component---src-pages-bikes-tsx" */),
  "component---src-pages-bytes-tsx": () => import("./../src/pages/bytes.tsx" /* webpackChunkName: "component---src-pages-bytes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-templates-bike-template-tsx": () => import("./../src/templates/bikeTemplate.tsx" /* webpackChunkName: "component---src-templates-bike-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../src/templates/postTemplate.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */)
}

